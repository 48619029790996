<template>
  <div
    class="card"
    :class="{
      'card-expand': expanded,
      'card-tabs': tabs,
      border: noShadow,
      'no-shadow': noShadow,
    }"
  >
    <div v-if="!noHeader" class="card-header">
      <div v-if="title" class="card-title header_title">{{ title }}</div>
      <slot name="header" />
      <div class="form-wrapper" v-if="searchable">
        <form v-if="searchable" @submit.prevent="searchData" class="form-group col px-0 mb-0">
          <div class="input-group">
            <input
              placeholder=""
              type="text"
              class="form-control"
              ref="searchInput"
            />
            <div class="input-group-prepend">
              <button @click="searchData" type="button" class="btn icon_btn m-0">
                <span class="material-symbols-outlined"> search </span>
              </button>
            </div>
          </div>
        </form>
      </div>
      <ul v-if="actions" class="actions opts_card pr-3">
        <dropdown v-if="filters.length" tag="li">
          <template #text>
            <i class="icon dripicons-experiment" />
          </template>
          <template #items>
            <form class="px-4 py-3" @submit.prevent="applyFilter">
              <template v-for="(filter, n) in filters">
                <h6 :key="n" class="dropdown-header">
                  {{ $tc(`app.${filter.name}`, 2) }}
                </h6>
                <template v-if="filter.type === 'checkbox'">
                  <div
                    v-for="option in filter.options"
                    :key="option.name"
                    class="form-group"
                  >
                    <div class="custom-control custom-checkbox form-check">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        :id="option.label"
                        :value="option.name"
                        v-model="filter.value"
                      />
                      <label class="custom-control-label" :for="option.label">{{
                        $t(`app.${option.label}`)
                      }}</label>
                    </div>
                  </div>
                </template>
                <div
                  v-else-if="filter.type === 'select'"
                  :key="filter.name"
                  class="form-group"
                >
                  <select
                    class="form-control"
                    id="defaultSelect"
                    v-model="filter.value"
                  >
                    <option value=""></option>
                    <option
                      v-for="option in filter.options"
                      :key="option.name"
                      :value="{ label: option.label, value: option.name }"
                    >
                      {{ option.label }}
                    </option>
                  </select>
                </div>
                <div
                  v-else-if="filter.type === 'text'"
                  :key="filter.name"
                  class="form-group"
                >
                  <input
                    type="text"
                    class="form-control"
                    v-model="filter.value"
                  />
                </div>
                <div
                  v-else-if="filter.type === 'range'"
                  :key="filter.name"
                  class="form-group"
                >
                  <date-range-picker
                    class="form-control"
                    v-model="filter.value"
                    :value="filter.value"
                    :options="options"
                  />
                </div>
              </template>
              <div class="dropdown-divider"></div>
              <button type="submit" class="btn btn-primary">
                {{ $t('app.search') }}
              </button>
            </form>
          </template>
        </dropdown>
        <li>
          <a href="javascript:void(0)" @click="reload">
            <span class="material-symbols-outlined title">refresh</span>
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" @click="expand">
            <!-- <i v-if="expanded" class="dripicons-contract-2"></i>
            <i v-else class="icon dripicons-expand-2"></i> -->
            <span v-if="expanded" class="material-symbols-outlined title">close_fullscreen</span>
            <span v-else class="material-symbols-outlined title">open_in_full</span>
          </a>
        </li>
        <slot name="actions" />
        <dropdown v-if="exportAction" tag="li">
          <template #text>
            <i class="icon dripicons-cloud-download" />
          </template>
          <template #items>
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              @click="exportData('csv')"
              >CSV</a
            >
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              @click="exportData('xlsx')"
              >XLSX</a
            >
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              @click="exportData('pdf')"
              >PDF</a
            >
          </template>
        </dropdown>
        <li v-if="addAcc">
          <a id="show-btn" href="javascript:;" @click="showModal">
            <span class="material-symbols-outlined add_person">add</span>
          </a>
          <b-modal
            id="add-person-modal"
            ref="add-person"
            size="md"
            scrollable
            hide-header
            hide-footer
          >
            <div class="container-fluid">
              <AddPerson />
            </div>
          </b-modal>
        </li>
        <template v-if="usersDep">
          <!-- <li>
            <span class="material-icons-outlined title"> domain </span>
          </li> -->
          <li v-modal="{ target: 'department-modal' }">
            <span class="material-icons-outlined add_person"> add </span>
          </li>
        </template>
      </ul>
    </div>
    <div class="card-body" :class="{ 'block-el': block }">
      <div v-if="filters" class="filters">
        <span
          v-for="(filter, n) in appliedFilters"
          :key="n"
          class="badge badge-light"
          ><b>{{ $tc(`app.${filter.name}`, 1) }}</b
          >&nbsp;
          <span v-if="filter.type == 'select'">{{ filter.value.label }}</span>
          <span v-else-if="filter.type != 'text'"
            ><span v-for="(val, i) in filter.value" :key="i"
              ><span v-if="i !== 0"> - </span>{{ val }}</span
            ></span
          >
          <span v-else>{{ filter.value }}</span>
          <a href="javascript:void(0)" @click="removeFilter(filter.name)">x</a>
        </span>
      </div>
      <slot name="body" />
      <!-- <div v-if="block && loading" class="qt-block-ui"></div> -->
      <div class="loading min-h-300 scrollable-loading" v-if="loading">
        <LoadingAnim />
      </div>
    </div>
    <slot />
    <div v-if="footer" class="card-footer bg-light">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import Dropdown from '@/components/Dropdown.vue';
import AddPerson from '@/components/rebranding/modalContent/AddPerson.vue';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';
// import DepartmentModal from '@/components/rebranding/departments/DepartmentModal.vue';

export default {
  name: 'Card',
  components: {
    Dropdown,
    AddPerson,
    LoadingAnim,
    // DepartmentModal,
  },
  props: {
    noHeader: Boolean,
    noShadow: Boolean,
    tabs: Boolean,
    title: String,
    block: Boolean,
    exportAction: String,
    search: {
      type: String,
      default: 'q',
    },
    msg: {
      type: String,
    },
    actions: Boolean,
    addAcc: Boolean,
    usersDep: Boolean,
    searchable: Boolean,
    searchQuery: null,
    filters: {
      type: Array,
      default() {
        return [];
      },
    },
    footer: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Out',
            'Nov',
            'Dez',
          ],
        },
        message: '',
      },
      expanded: false,
      query: [],
      appliedFilters: {},
    };
  },
  created() {
    this.applyFilter();
  },
  methods: {
    searchData() {
      const word = this.$refs.searchInput.value;
      // console.log('Fire: ', this.message = word);
      this.message = word;
      this.$emit('messageChanged', this.message);
    },
    applyFilter() {
      const query = [];
      this.filters?.forEach((element) => {
        if (element.type === 'checkbox') {
          if (!element.value.length) return;
          this.query[element.name] = {
            in: [],
          };
          element.value.forEach((value) => {
            this.query[element.name].in.push(value);
          });
        } else if (element.type === 'select') {
          this.query[element.name] = element.value.value;
        } else {
          this.query[element.name] = element.value;
        }
        if (element.value) {
          this.appliedFilters[element.name] = JSON.parse(
            JSON.stringify(element),
          );
        }
      });
      this.$emit('filter', this.query);
    },
    removeFilter(filterName = '') {
      this.filters?.forEach((filter) => {
        if (!filterName || filter.name === filterName) {
          filter.value = '';
        }
      });
      this.appliedFilters = {};
      this.applyFilter();
    },
    reload() {
      this.$emit('reload');
    },
    expand() {
      this.expanded = !this.expanded;
    },
    showModal() {
      this.$refs['add-person'].show();
    },
    hideModal() {
      this.$refs['add-person'].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['add-person'].toggle('#toggle-btn');
    },
    exportData(format) {
      this.$store
        .dispatch(this.exportAction, {
          ...this.query,
          export: format,
        })
        .then((response) => {
          const data = response.data;
          const name = response.headers['content-disposition'];
          const blob = new Blob([data], {
            type: response.headers['content-type'],
          });
          if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, name);
          } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
              // feature detection
              // Browsers that support HTML5 download attribute
              const url = URL.createObjectURL(blob);
              link.setAttribute('href', url);
              link.setAttribute('download', name);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
        });
      // this.$emit('export', format);
    },
  },
};
</script>

<style lang="scss" scoped>
.block-el {
  min-height: 100px;
}
.card-title {
  margin-bottom: 0;
}
.filters {
  text-align: right;
  .badge {
    margin-left: 5px;
    a {
      margin-left: 10px;
    }
  }
}

/* Rebranding */
.eye_icon {
  color: #bfc5cb;
}
.form-group {
  label {
    color: var(--foreground);
    font-size: 16px;
    margin-bottom: 20px;
  }
  input:-ms-input-placeholder {
    text-transform: lowercase !important;
  }
  input::-webkit-input-placeholder {
    text-transform: lowercase !important;
  }
  input::placeholder {
    text-transform: lowercase !important;
  }

  #inputPassword {
    border-right-color: transparent;
    border-right-width: 0;
  }

  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: transparent;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}
.darkmode .rebranding .form-control,
.rebranding .form-control {
  border-right: unset !important;
}
.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left-color: transparent;
}

.actions {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  z-index: 5;
  display: flex;
  align-items: center;
  gap: 15px;
}

.actions > li {
  margin-left: 0;
}

.scrollable-loading {
  position: absolute;
  top: 0;
}

.form-wrapper {
  flex: 1 0 20%;
}

@media (min-width: 1660px){
  .form-wrapper {
    min-width: 0;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex-basis: 0;
    flex-grow: 1;
  }
}

@media (max-width: 767.98px) {
  ::v-deep .actions.top-right{
    top: 22px !important;
  }
  .rebranding .card .card-header .header_title,
  .rebranding .header_title {
    width: 100%;
  }

  .actions {
    width: 43%;
  }

  .form-wrapper {
    flex: 1 0 40%;
  }
}
</style>
