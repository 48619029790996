<template>
  <div class="user-card-content">
    <!-- <div class="testing-container">
      <p>Total Agents: {{totalAgents}}</p>
    </div> -->
    <Card ref="card">
      <template #header>
        Contas
        <ListGroup top-right>
          <ListGroupItem>
            <a role="button" @click="fetch(1)"
              ><span class="material-icons-outlined"> refresh </span></a
            >
          </ListGroupItem>
          <ListGroupItem>
            <a role="button" @click="$refs['create-user'].show()"
              ><span class="material-icons-outlined"> add </span></a
            >
          </ListGroupItem>
        </ListGroup>
      </template>
      <div class="row">
        <Table :fields="tableFields" :items="users" :busy="loading">
          <template #actions="item">
            <Dropdown>
              <template v-slot:text>
                <span class="material-symbols-outlined title"> more_vert </span>
              </template>
              <template #items>
                <a
                  href="javascript:void(0)"
                  class="dropdown-item"
                  v-modal="'create-user'"
                  @click="editUser(item)"
                >
                  <!-- v-modal="{ target: 'department-modal', data: item } -->
                  {{ $t('generic-str.edit') }}
                </a>
                <button class="dropdown-item" @click="destroy(item.id)">
                  {{ $t('generic-str.remove') }}
                </button>
              </template>
            </Dropdown>
          </template>
        </Table>
      </div>
        <pagination :totalPages="totalPages" v-model="currentPage" @change="fetch" />
    </Card>
    <Modal ref="edit-user" title="Editar Usuário" @confirm="updateUser">
      <form v-if="editingUser">
        <FormGroup label="Email">
          <FormInput readonly v-model="editingUser.email" />
        </FormGroup>
        <FormGroup label="Nome Completo" required>
          <FormInput v-model="editingUser.name" />
        </FormGroup>
        <FormGroup label="Departamento" required>
          <FormSelect
            :options="departments"
            text-field="name"
            value-field="id"
            v-model="editingUser.department"
          />
        </FormGroup>
        <FormGroup v-if="editingUser.role !== 'owner'" label="Função" required>
          <FormSelect :options="roles" v-model="editingUser.role" required />
          <Alert>
            <p v-if="editingUser.role === 'user'">
              Ver relatórios, interagir com os canais. (Chat) <!-- (Omni Business) -->
              Atendimento.
            </p>
            <p v-else-if="editingUser.role === 'billing'">
              Permissão a todos os relatórios e área financeira da plataforma.
            </p>
            <p v-else-if="editingUser.role === 'developer'">
              Permissão de configurar webhook, widget (Yuper Chat), analise de
              relatórios da plataforma.
            </p>
            <p v-else-if="editingUser.role === 'admin'">
              Permissão total da plataforma, com exceção da contratação de
              produtos ou pacotes de serviço.
            </p>
            <p v-else-if="editingUser.role === 'sales'">Relatórios Gestão.</p>
          </Alert>
        </FormGroup>
        <FormCheckbox id="checkbox" ref="editCheckbox" v-model="editingUser.is_agent" @input="checkAgents('checkbox', true)"
          >Agente</FormCheckbox
        >
        <FormGroup
          v-if="editingUser.is_agent"
          label="Máximo de atendimentos"
          required
        >
          <FormInput v-model="editingUser.max_calls_at_time" />
        </FormGroup>
      </form>
    </Modal>
    <Modal ref="create-user" title="Cadastrar Usuário" @confirm="storeUser">
      <form>
        <FormGroup label="Email">
          <FormInput v-model="form.email" />
        </FormGroup>
        <FormGroup label="Departamento" required>
          <FormSelect
            :options="departments"
            text-field="name"
            value-field="id"
            v-model="form.departments"
          />
        </FormGroup>
        <FormGroup v-if="form.role !== 'owner'" label="Função" required>
          <FormSelect :options="roles" v-model="form.role" required />
          <Alert v-if="form.role">
            <p v-if="form.role === 'user'">
              Ver relatórios, interagir com os canais. (Chat) <!-- (Omni Business) -->
              Atendimento.
            </p>
            <p v-else-if="form.role === 'billing'">
              Permissão a todos os relatórios e área financeira da plataforma.
            </p>
            <p v-else-if="form.role === 'developer'">
              Permissão de configurar webhook, widget (Yuper Chat), analise de
              relatórios da plataforma.
            </p>
            <p v-else-if="form.role === 'admin'">
              Permissão total da plataforma, com exceção da contratação de
              produtos ou pacotes de serviço.
            </p>
            <p v-else-if="form.role === 'sales'">Relatórios Gestão.</p>
          </Alert>
        </FormGroup>
        <FormCheckbox id="checkbox2" v-model="form.is_agent" @input="checkAgents('checkbox2', false)" :checked="form.is_agent"
          >Agente</FormCheckbox
        >
        <FormGroup v-if="form.is_agent" label="Máximo de atendimentos" required>
          <FormInput v-model="form.max_calls_at_time" />
        </FormGroup>
      </form>
    </Modal>
  </div>
</template>

<script>
// @ is an alias to /src
import Swal from 'sweetalert2';
import Card from '@/components/rebranding/card/Card.vue';
import Table from '@/components/rebranding/table/Table.vue';
import ListGroup from '@/components/rebranding/list/ListGroup.vue';
import ListGroupItem from '@/components/rebranding/list/ListGroupItem.vue';
import Modal from '@/components/rebranding/modal/Modal.vue';
import FormGroup from '@/components/rebranding/form/FormGroup.vue';
import FormInput from '@/components/rebranding/form/FormInput.vue';
import FormSelect from '@/components/rebranding/form/FormSelect.vue';
import FormCheckbox from '@/components/rebranding/form/FormCheckbox.vue';
import Pagination from '@/components/PaginationComponent.vue';
import Alert from '@/components/rebranding/alert/Alert.vue';
import Dropdown from '@/components/Dropdown.vue';

import axios from '@/plugins/axios';

const webApi = axios();

export default {
  name: 'UsersCard',
  components: {
    Card,
    Table,
    Dropdown,
    ListGroup,
    ListGroupItem,
    Modal,
    FormGroup,
    FormInput,
    FormSelect,
    FormCheckbox,
    Alert,
    Pagination,
  },
  data() {
    return {
      loading: true,
      tableFields: [
        {
          key: 'name',
        },
        {
          key: 'email',
        },
        {
          key: 'pivot.role',
          label: this.$tc('app.role', 1),
          localize: true,
        },
        {
          key: 'departments.0.name',
          label: this.$tc('app.department', 1),
        },
        {
          key: 'pivot.is_agent',
          label: this.$tc('app.agent', 1),
          type: Boolean,
        },
      ],
      departments: [],
      totalPages: 1,
      fullscreen: false,
      q: '',
      page: 1,
      pages: 1,
      users: [],
      editingUser: null,
      form: {},
      message: '',
      totalAgents: 19,
      currentPage: 1,
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    roles() {
      const roles = [
        { value: 'user', text: 'Analista' },
        { value: 'billing', text: 'Financeiro(a)' },
        { value: 'developer', text: 'Desenvolvedor(a)' },
        { value: 'admin', text: 'Gestor(a)' },
      ];

      if (this.$store.state.account.whitelabel_id == null) {
        roles.push({ value: 'sales', text: 'Executivo(a) de Vendas' });
      }
      return roles;
    },
  },
  created() {
    this.fetch();
    this.fetchDepartments();
  },
  mounted() {
    this.$root.$on('UsersCardReload', () => {
      this.fetch();
    });
  },
  methods: {
    agentsAmount() {
      webApi.get('/accounts/agentsAmount').then(
        (response) => {
          this.totalAgents = response.data;
        },
      );
    },
    showModalInfo(item) {
      console.info('$emit:UsersModal', item);
      this.$root.$emit('UsersModal', item);
      this.$refs['users-modal'].showHandler('users-modal', item);
    },
    fetch(page = 1) {
      this.loading = true;
      this.currentPage = page;
      this.$store
        .dispatch('fetchUsers', { page: this.currentPage, q: this.q })
        .then((response) => {
          this.users = response.data;
          this.totalPages = response.last_page;
          this.loading = false;
        });
      this.agentsAmount();
    },
    fetchDepartments() {
      this.$store.dispatch('fetchDepartments').then((response) => {
        this.departments = response.data;
      });
    },
    checkAgents(checkbox, isEditing) {
      const checkBox = document.getElementById(checkbox);
      // console.log(`Element ${checkBox}\nCheck state ${checkBox.checked}`);
      if (this.totalAgents > 1 && checkBox.checked) {
        Swal.fire({
          title: this.$t('app.warning'),
          text: this.$t('users.charge'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('generic-str.confirm'),
          cancelButtonText: this.$t('app.cancel'),
        }).then((result) => {
          // console.log('Result: ', result);
          // console.log('is_agent checked', result.isConfirmed);
          isEditing ? this.editingUser.is_agent = result.isConfirmed : this.form.is_agent = result.isConfirmed;
          checkBox.checked = result.isConfirmed;
        });
      }
    },
    updateUser() {
      this.updating = true;
      this.$store
        .dispatch('updateUser', this.editingUser)
        .then((response) => {
          this.fetch();
        })
        .finally(() => {
          this.updating = false;
        });
    },
    editUser(user) {
      this.editingUser = {
        id: user.id,
        email: user.email,
        department: user.departments.length ? user.departments[0].id : '',
        is_agent: user.pivot.is_agent,
        max_calls_at_time: user.pivot.max_calls_at_time,
        name: user.name,
        role: user.pivot.role,
      };
      console.log(this.editingUser);
      this.$refs['edit-user'].show();
    },
    storeUser() {
      this.updating = true;
      this.$store
        .dispatch('storeUser', this.form)
        .then((response) => {
          this.fetch();
        })
        .finally(() => {
          this.updating = false;
        });
    },
    search(q) {
      // console.log('What arrives: ', q);
      this.q = q;
      this.fetch(1);
    },
    /* search() {
      console.log('What arrives: ', this.qSearch);
      this.$emit('search', this.qSearch);
    }, */
    destroy(id) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text: this.$t('user.destroy'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
        cancelButtonText: this.$t('app.cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          this.$store.dispatch('destroyUser', id).then((response) => {
            this.fetch();
          });
        }
      });
    },
    changePage(page) {
      /* this.page = page;
      this.$emit('change-page', this.page); */
      this.fetch(page);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .pagination_row {
  display: none !important;
}

::v-deep {
  .user-card-content .modal {
    overflow-y:auto !important;
  }
  .table-responsive {
    overflow: visible;
  }
}
</style>
