<template>
  <card
    ref="card"
    :actions="true"
    :searchable="true"
    :usersDep="true"
    block
    :title="$tc('app.department', 2)"
    :loading="loading"
    @reload="fetch"
    @messageChanged="search"
  >
    <template #body>
      <data-table
        :data="departments"
        :pages="pages"
        :columns="[
          'name',
          'description',
          {
            key: 'active',
            label: 'status',
          },
        ]"
        @search="search"
        @change-page="fetch"
      >
        <!-- <template #buttons>
          <a
            v-modal="{ target: 'department-modal' }"
            href="javascript:void(0)"
            class="btn btn-primary"
            aria-expanded="false"
          >
            <i
              class="
                fas
                fa-plus-circle
                text-white
                font-size-22
                v-align-text-bottom
              "
            ></i>
          </a>
        </template> -->
        <template #actions="item">
          <dropdown>
            <template v-slot:text>
              <span class="material-symbols-outlined title"> more_vert </span>
            </template>
            <template #items>
              <a
                href="javascript:void(0)"
                class="dropdown-item"
                @click="showModalInfo(item)"
              >
              <!-- v-modal="{ target: 'department-modal', data: item } -->
                {{ $t('generic-str.edit') }}
              </a>
              <button class="dropdown-item" @click="destroy(item.id)">
                {{ $t('generic-str.remove') }}
              </button>
            </template>
          </dropdown>
        </template>
      </data-table>
      <department-modal
        id="department-modal"
        @created="fetch"
        @updated="fetch"
        ref="department-modal"
      ></department-modal>
    </template>
    <div class="card-footer">
      <pagination :lastPage="pages" @change="changePage" />
    </div>
  </card>
</template>

<script>
// @ is an alias to /src
import Swal from 'sweetalert2';
import Card from '@/components/rebranding/Card2.vue';
import DataTable from '@/components/rebranding/DataTable.vue';
import Dropdown from '@/components/Dropdown.vue';
import DepartmentModal from '@/components/rebranding/departments/DepartmentModal.vue';
import DepartmentService from '@/services/department.service';
import Pagination from '@/components/rebranding/Pagination.vue';
import Modal from '@/mixins/Modal';

export default {
  name: 'DepartmentsCard',
  mixins: [Modal],
  components: {
    Card,
    DataTable,
    Dropdown,
    DepartmentModal,
    Pagination,
  },
  data() {
    return {
      loading: true,
      q: '',
      page: 1,
      pages: 1,
      departments: [],
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    showModalInfo(item) {
      // console.info(item);
      // this.$root.$emit('UsersModal', item);
      this.$refs['department-modal'].showHandler('department-modal', item);
    },
    fetch(page = this.page) {
      this.page = page;
      this.loading = true;
      this.$store
        .dispatch('fetchDepartments', { page, q: this.q })
        .then((response) => {
          this.departments = response.data;
          this.pages = response.last_page;
          this.loading = false;
        });
    },
    destroy(id) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text: this.$t('departments.swal.remove-msg'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
        cancelButtonText: this.$t('app.cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          DepartmentService.removeDepartment(id).then(
            () => {
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('departments.toast.deleted'),
                type: 'success',
              });
              this.loading = false;
              this.fetch();
            },
            (error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
                  error.code
                }`,
                content: error.message,
                type: 'danger',
              });
              this.loading = false;
            },
          );
        }
      });
      /* Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text: this.$t('user.destroy'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          this.$store.dispatch('destroyUser', id).then((response) => {
            this.fetch();
          });
        }
      }); */
    },
    changePage(page) {
      /* this.page = page;
      this.$emit('change-page', this.page); */
      this.fetch(page);
    },
    search(q) {
      this.q = q;
      this.fetch(1);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .pagination_row {
  display: none !important;
}

::v-deep {
  .table-responsive {
    overflow: visible;
  }
}
</style>
